<template>
  <spinner v-if="loadData"></spinner>
  <div v-if="!loadData">
    <div class="grid grid-cols-5 gap-7 px-7 pt-7">
      <div class="col-span-3 mb-4 xl:mb-2">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex flex-col">
            <div class="w-full">
              <div class="inline-block min-w-full align-middle">
                <div class="w-full">
                  <div v-if="entityStore.entity">
                    <div class="p-7 pb-12 border-b border-gray-200">
                      <form action="">
                        <div v-if="entityStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
                          {{ entityStore.error }}
                        </div>

                        <div v-if="isEntitySaved" class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                          {{ $t('common.documentSaved') }}
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-3">
                          <div class="sm:col-span-2">
                            <template v-if="canEditAttribute('name')">
                              <template v-if="!entityStore.entity.id || isEditName">
                                <input-text v-model="entityStore.entity.name" :label="$t('document.documentName') + ':'"/>
                                <p v-if="v$.name.$error" class="mt-2 text-sm text-red-600"> {{ v$.name.$errors[0].$message }} </p>
                              </template>
                              <template v-else>
                                <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                                <button type="button" @click.prevent="isEditName.value = true">
                                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.586 1.58616C10.7705 1.39514 10.9912 1.24278 11.2352 1.13796C11.4792 1.03314 11.7416 0.977969 12.0072 0.975661C12.2728 0.973354 12.5361 1.02396 12.7819 1.12452C13.0277 1.22508 13.251 1.37359 13.4388 1.56137C13.6266 1.74916 13.7751 1.97246 13.8756 2.21825C13.9762 2.46405 14.0268 2.72741 14.0245 2.99296C14.0222 3.25852 13.967 3.52096 13.8622 3.76497C13.7574 4.00898 13.605 4.22967 13.414 4.41416L12.621 5.20716L9.793 2.37916L10.586 1.58616ZM8.379 3.79316L0 12.1722V15.0002H2.828L11.208 6.62116L8.378 3.79316H8.379Z" fill="#111928"/>
                                  </svg>
                                </button>
                              </template>
                            </template>
                            <template v-else>
                              <span class="text-3xl font-medium mr-4">{{ entityStore.entity.name }}</span>
                            </template>

                            <div class="mt-2 text-sm" v-if="entityStore.entity.id">{{ entityStore.entity.user.name }}</div>
                          </div>
                          <div class="sm:col-span-1" v-if="entityStore.entity.id">
                            <div class="text-right text-sm">
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.created') }}:</span> {{ moment(entityStore.entity.created_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2"><span class="text-gray-500">{{ $t('document.updated') }}:</span> {{ moment(entityStore.entity.updated_at).format('DD.MM.YYYY') }}</p>
                              <p class="mb-2" v-if="checkRole('admin')">
                                <router-link :to="{name: 'UserLog', query: {object: 'App\\Models\\Document', id: entityStore.entity.id}}" target="_blank" class="inline-block mt-2 px-3 py-2 rounded-md text-white font-medium bg-blue-500">{{ $t('common.toLogs') }}</router-link>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2" v-if="entityStore.entity.status">
                          <div class="sm:col-span-2">
                            <div class="inline-block px-4 py-1 rounded-xl mr-4 whitespace-nowrap opacity-100 cursor-default" :class="['bg-status-' + entityStore.entity.status]">
                              <span>{{ statusListAll.find(item => item.id === entityStore.entity.status).name }}</span>
                            </div>
                          </div>
                        </div>

                        <hr class="mb-4">

                        <table class="mb-4" v-if="(!canEditAttribute('developer_id') && entityStore.entity.developer)">
                          <tr v-if="entityStore.entity.developer && !checkRole('developer')">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assigned') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.developer.name }}</td>
                          </tr>
                          <tr v-if="!checkRole('developer') && entityStore.entity.assigned">
                            <td class="pb-3 font-medium text-basic-gray pr-12">{{ $t('document.assignedManager') }}:</td>
                            <td class="pb-3 text-sm">{{ entityStore.entity.assigned.name }}</td>
                          </tr>
                        </table>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2" v-if="canEditAttribute('type')" v-show="typeListAllowed.length > 1">
                            <input-select-lite v-model="entityStore.entity.type" :options="typeListAllowed" :label="$t('document.type') + ':'"/>
                            <p v-if="v$.type.$error" class="mt-2 text-sm text-red-600"> {{ v$.type.$errors[0].$message }} </p>
                          </div>

                          <template v-if="canEditAttribute('developers') && !checkRole('developer')">
                            <div class="sm:col-span-1">
                              <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.developerType') }}:</label>
                              <Multiselect v-model="entityStore.entity.draft_developer_type_id" @change="filterDraftDevelopersByType" :options="developerTypes" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                            </div>
                            <div class="sm:col-span-1">
                              <label class="block mb-2 font-medium text-basic-gray">{{ $t('document.developer') }}:</label>
                              <Multiselect v-model="entityStore.entity.draft_developers" @select="chooseDraftDevelopers" :options="filteredDevelopers" :mode="'tags'" :valueProp="'id'" track-by="name" label="name" :close-on-select="false" :searchable="true"/>
                              <p v-if="v$.draft_developers.$error" class="mb-2 mt-2 text-sm text-red-600"> {{ v$.draft_developers.$errors[0].$message }} </p>
                            </div>
                          </template>
                        </div>

                        <div class="grid gap-4 mb-4 sm:grid-cols-2">
                          <div class="sm:col-span-2">
                            <input-textarea v-model="entityStore.entity.description" :label="$t('document.description') + ':'" :readonly="!canEditAttribute('description')"/>
                          </div>
                        </div>

                        <div style="border: 1px solid #919191; border-radius: 15px; padding: 10px 15px; margin-bottom: 25px;" v-for="(fileGroupBlocks, index) in entityStore.entity.fileGroups">
                          <div style="margin-bottom: 10px; padding: 10px 0 10px 0;">
                            <span style="background: aliceblue; padding: 10px 15px; border-radius: 15px;">{{fileGroupBlocks.groupName}}</span>
                          </div>
                          <div>
                            <div v-for="fileBlock in fileGroupBlocks.data">
                              <div class="grid gap-4 mb-4 sm:grid-cols-2 p-4" :class="{'bg-[#effbe3] border-[#effbe3]': entityStore.entity.doc_types_agreed.includes(fileBlock.value.doc_type), 'border': true}">
                                <div class="sm:col-span-1">
                                  <div class="flex items-center mb-2">
                                    <input v-if="statusList.filter(item => item.id !== entityStore.entity.status).length && !checkRole('developer')" :id="'doc-type-files-' + fileBlock.value.doc_type" v-model="entityStore.entity.doc_types_agreed" :value="fileBlock.value.doc_type" type="checkbox" class="w-4 h-4 bg-gray-50 mr-2 rounded border-gray-300 focus:ring-3 checked:bg-primary focus:ring-primary-300">
                                    <label :for="'doc-type-files-' + fileBlock.value.doc_type" class="block font-medium text-basic-gray">{{ $t('document.files') }} «{{ documentTypeListAll.find(documentType => documentType.id === fileBlock.value.doc_type)?.name }}»:</label>
                                  </div>

                                  <div v-if="fileBlock.value.files.length" class="mt-4">
                                    <div v-for="file in fileBlock.value.files" class="mb-3">
                                      <div class="flex">
                                        <a :href="file.src" target="_blank" class="display-block w-48 whitespace-nowrap text-ellipsis overflow-hidden mr-4 hover:text-blue-600" :title="file.original_name">{{ file.original_name }}<div class="text-xs">{{ moment(file.created_at).format('DD.MM.YYYY HH:mm') }}</div></a>
                                        <span class="text-xs text-gray-400 mr-2">{{ humanFileSize(file.size) }}</span>
                                        <button @click.prevent="deleteFile(file.id)" type="button" class="hover:opacity-60 h-5" v-if="canEditAttribute('file_delete')">
                                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M3.3335 5.83333H16.6668M15.8335 5.83333L15.111 15.9517C15.0811 16.3722 14.8929 16.7657 14.5844 17.053C14.2759 17.3403 13.87 17.5 13.4485 17.5H6.55183C6.13028 17.5 5.72439 17.3403 5.4159 17.053C5.10742 16.7657 4.91926 16.3722 4.88933 15.9517L4.16683 5.83333H15.8335ZM12.5002 5.83333V3.33333C12.5002 3.11232 12.4124 2.90036 12.2561 2.74408C12.0998 2.5878 11.8878 2.5 11.6668 2.5H8.3335C8.11248 2.5 7.90052 2.5878 7.74424 2.74408C7.58796 2.90036 7.50016 3.11232 7.50016 3.33333V5.83333H12.5002Z" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="sm:col-span-1" v-if="!docTypesAgreed.includes(fileBlock.value.doc_type) && (canEditAttribute('file_upload') || engineerDocTypes.includes(fileBlock.value.doc_type))">
                                  <div class="sm:col-span-2">
                                    <drop-file v-model="fileBlock.value.newFiles"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p v-if="v$.files.$error" class="mb-2 mt-2 text-sm text-red-600"> {{ v$.files.$errors[0].$message }} </p>
                      </form>
                    </div>
                    <div class="p-7 flex justify-between">
                      <div>
                        <div class="flex flex-wrap">
                          <div class="flex items-center mb-4" v-for="option in statusList.filter(item => item.id !== entityStore.entity.status)">
                            <label @click.prevent="saveEntity(option.id)" class="inline-block px-4 py-1 rounded-xl mr-4 cursor-pointer whitespace-nowrap opacity-100 hover:opacity-80" :class="['bg-operation-' + option.id]">
                              <span>{{ option.process }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-2 mb-4 xl:mb-2" v-if="entityStore.entity.id || entityStore.entity.messages.length">
        <div class="mb-5 bg-white rounded-lg border border-gray-200 bg-gray-50">
          <div class="flex justify-between items-center p-7 border-b border-gray-200">
            <div class="text-3xl font-medium">
              {{ $t('document.remarksTitle') }}
            </div>
          </div>
          <div class="p-7 pt-4">
            <div class="flex-1 justify-between flex flex-col h-[50vh]">
              <div id="messages" class="flex flex-col space-y-4 py-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                <div class="chat-message" v-for="message in entityStore.entity.messages">
                  <div class="flex items-end" :class="{'justify-end': !message.is_answer}">
                    <div class="flex flex-col space-y-2 text-xs max-w-xs mr-2 items-start">
                      <div>
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none" :class="{'bg-gray-300 text-gray-600': message.is_answer, 'bg-blue-600 text-white': !message.is_answer}">
                          <div class="text-xs">
                            {{ message.user.name }}
                          </div>
                          <div class="text-xs">
                            {{ moment(message.created_at).format('DD.MM.YYYY HH:mm') }}
                          </div>
                          <div class="text-sm mt-1">
                            {{ message.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0" v-if="entityStore.entity.id && canEditAttribute('remarks')">
                <div class="relative flex">
                  <textarea
                      :placeholder="$t('chat.write_message')"
                      v-model="entityStore.entity.remarks"
                      rows="5"
                      class="border-0 w-full focus:outline-none resize-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pr-20 bg-gray-200 rounded-md py-3"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRegistryStore} from "../../stores/RegistryStore";
import {checkRole} from "../../utilits/functions";
import Spinner from "../flowbite/spinner.vue";
import {computed, reactive, ref, watch, onUpdated} from "vue";
import InputText from "../flowbite/form/inputText.vue";
import useVuelidate from '@vuelidate/core'
import {useRoute, useRouter} from 'vue-router'
import {helpers, required} from '../../utilits/custom-validators.js'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import InputTextarea from "../flowbite/form/inputTextarea.vue";
import DropFile from "../flowbite/form/dropFile.vue";
import moment from "moment";
import Multiselect from '@vueform/multiselect'
import i18n from "../../i18n.js";

const entityStore = useRegistryStore()
entityStore.error = null

const isEditName = ref(false)
const loadData = ref(true)
const isEntitySaved = ref(false)

const engineerDocTypes = ref([])

const statusList = ref([])
const statusListAll = ref([])
const typeList = ref([])
const typeListAllowed = ref([])
const documentTypeList = ref([])
const documentTypeListAll = ref([])
const changeStatusList = ref({})
const createStatusList = ref({})
const developers = ref([])
const filteredDevelopers = ref([])
const developerTypes = ref([])
const docTypesAgreed = ref([])

// Первоначальные данные
const route = useRoute()
const router = useRouter()

entityStore.getDirectories().then(() => {
  statusList.value = [...statusList.value, ...entityStore.statusListAll]
  statusListAll.value = [...statusListAll.value, ...entityStore.statusListAll]
  documentTypeList.value = [...documentTypeList.value, ...entityStore.documentTypeList]
  documentTypeListAll.value = [...documentTypeListAll.value, ...entityStore.documentTypeListAll]
  typeList.value = [...typeList.value, ...entityStore.typeList]
  typeListAllowed.value = [...typeListAllowed.value, ...entityStore.typeListAllowed]
  changeStatusList.value = entityStore.changeStatusList
  createStatusList.value = entityStore.createStatusList
  developerTypes.value = entityStore.developerTypes

  developers.value.push({
    id: -1,
    name: i18n.global.t('document.chooseAll'),
    type_id: 0
  })
  developers.value = [...developers.value, ...entityStore.developers]

  filteredDevelopers.value = developers.value

  if (route.params.id > 0) {
    entityStore.getEntityById(route.params.id).then(() => {
      loadData.value = false

      setAvailableStatusList()

      if (checkRole('developer')) {
        entityStore.entity.doc_types = documentTypeList.value.map(documentType => documentType.id)
      }

      if (checkRole('engineer')) {
        engineerDocTypes.value = documentTypeList.value.filter(documentType => documentType.status_id === entityStore.entity.status).map(documentType => documentType.id)

        engineerDocTypes.value.forEach((id) => {
          entityStore.entity.doc_types.push(id)
        })
      }

      docTypesAgreed.value = entityStore.entity.doc_types_agreed
    })
  } else {
    if (!checkRole('developer')) {
      router.push({name: 'Dashboard'})
    }

    entityStore.setEmptyEntity().then(() => {
      loadData.value = false

      statusList.value = statusList.value.filter(status => createStatusList.value.includes(status.id))

      entityStore.entity.status = statusList.value[0]?.id
      entityStore.entity.type = typeListAllowed.value[0]?.id

      if (checkRole('developer')) {
        entityStore.entity.doc_types = documentTypeList.value.map(documentType => documentType.id)
      }
    })
  }
})

onUpdated(() => {
  setChatScroll()
})

// Валидация
const developerIdRule = !checkRole('developer') ? {
  developerRequired: helpers.withMessage(i18n.global.t('common.validation.chooseExponent'), () => {
    return !canEditAttribute('developers') || entityStore.entity.draft_developers.length
  })
} : {}

const filesRule = {
  filesRule: helpers.withMessage(i18n.global.t('common.validation.uploadFiles'), () => {
    return !entityStore.entity.files.filter((files) => {
      return !files.files.length && !files.newFiles.length
    }).length
  })
}

const docTypesRule = {
  developerRequired: helpers.withMessage(i18n.global.t('common.validation.required'), () => {
    if (!canEditAttribute('doc_types') || entityStore.entity.type === 2) {
      return true
    }

    return entityStore.entity.doc_types.length
  })
}

const rules = computed(() => ({
  name: {required},
  type: {required},
  doc_types: docTypesRule,
  status: {required},
  draft_developers: developerIdRule,
  files: filesRule,
}));

let state = reactive({
  name: '',
  type: '',
  doc_types: [],
  status: '',
  developer_id: '',
  description: '',
  remarks: '',
  deadline: '',
  files: [],
  fileGroups: [],
  developers: [],
});

const createGroupByDocTypeGroup = (files) => {
  let items = [];

  files.forEach((file) => {
    const docTypeGroupValue = entityStore.documentTypeListAll.find(docTypeGroup => docTypeGroup.id === file.doc_type)?.docTypeGroup;

    const docTypeGroupId = docTypeGroupValue?.id || 0;
    const docTypeGroupName = docTypeGroupValue?.name || 'Общие';

    let group = items.find(item => item.groupName === docTypeGroupName);

    if (!group) {
      group = {
        groupName: docTypeGroupName,
        data: []
      };
      items.push(group);
    }

    // Добавляем информацию о файле в массив data
    group.data.push({
      idDocGroup: docTypeGroupId,
      groupName: docTypeGroupName,
      value: file
    });
  });

  const newFileGroups = Object.values(items);

  if (JSON.stringify(entityStore.entity.fileGroups) !== JSON.stringify(newFileGroups)) {
    entityStore.entity.fileGroups = newFileGroups;
  }
};

watch(entityStore, (entityStore) => {
  if (entityStore.entity) {
    state.name = entityStore.entity.name;
    state.type = entityStore.entity.type;
    state.doc_types = entityStore.entity.doc_types;
    state.status = entityStore.entity.status;
    state.developer_id = entityStore.entity.developer_id;
    state.description	= entityStore.entity.description;
    state.remarks	= entityStore.entity.remarks;
    state.deadline	= entityStore.entity.deadline;
    state.files	= entityStore.entity.files;
    state.fileGroups = entityStore.entity.fileGroups;

    if (entityStore.entity.doc_types !== undefined) {
      createFileBlocks();
    }
  }
})

const v$ = useVuelidate(rules, state)

// Работа с данными
const createFileBlocks = () => {
  entityStore.entity.doc_types.forEach((doc_type) => {
    if (!entityStore.entity.files.find(item => item.doc_type === doc_type)) {
      entityStore.entity.files.push({
        doc_type: doc_type,
        files: [],
        newFiles: [],
      });
    }
  })

  entityStore.entity.files.forEach((files, index) => {
    if (!entityStore.entity.doc_types.includes(files.doc_type) && !files.files.length) {
      entityStore.entity.files.splice(index, 1)
    }
  });

  createGroupByDocTypeGroup(entityStore.entity.files);
}

const saveEntity = (newStatus) => {
  entityStore.error = null

  v$.value.$validate()

  if (!v$.value.$error) {
    loadData.value = true

    let isNew = entityStore.entity.id === undefined

    entityStore.entity.new_status = newStatus

    entityStore.storeEntity(entityStore.entity).then(() => {
      if (!entityStore.error && isNew) {
        router.push({name: 'RegistryEdit', params: {id: entityStore.entity.id}})
      }

      loadData.value = false

      if (!entityStore.error) {
        setAvailableStatusList()

        isEntitySaved.value = true

        setTimeout(() => {
          isEntitySaved.value = false
        }, 4000)
      }
    })

    v$.value.$reset()
  }
}

const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const deleteFile = (fileId) => {
  if (confirm('Вы действительно хотите удалить файл?')) {
    entityStore.deleteFile(fileId).then(() => {
    });
  }
}

const setChatScroll = () => {
  let el = document.getElementById('messages')

  if (el) {
    el.scrollTop = el.scrollHeight
  }
}

const setAvailableStatusList = () => {
  let availableStatusList = changeStatusList.value[entityStore.entity.type][entityStore.entity.status]

  if (typeof availableStatusList === 'undefined') {
    availableStatusList = []
  }

  statusList.value = statusListAll.value.filter(status => availableStatusList.includes(status.id) || status.id === entityStore.entity.status)
}

const canEditAttribute = (attribute) => {
  if (!entityStore.entity.id) {
    return true
  }

  return entityStore.entity.permissions.edit[attribute];
}

const chooseDraftDevelopers = (option) => {
  if (option === -1) {
    entityStore.entity.draft_developers = filteredDevelopers.value.map(item => item.id).filter(item => item !== -1)
  }
}

const filterDraftDevelopersByType = (developersTypeIds) => {
  filteredDevelopers.value = developers.value.filter((item) => {
    return item.id === -1 || !developersTypeIds.length || developersTypeIds.includes(item.type_id)
  })

  let filteredIds = filteredDevelopers.value.map(item => item.id)

  entityStore.entity.draft_developers = entityStore.entity.draft_developers.filter(id => filteredIds.includes(id))
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>