import {defineStore} from 'pinia'
import axios from "axios";
import {ref} from 'vue';

export const useRegistryStore = defineStore('registryStore', () => {
    const developers = ref([]);
    const statusList = ref([]);
    const statusListAll = ref([]);
    const documentTypeList = ref([]);
    const documentTypeListAll = ref([]);
    const developerTypes = ref([]);
    const changeStatusList = ref({});
    const createStatusList = ref({});
    const typeList = ref([]);
    const typeListAllowed = ref([]);
    const entities = ref([]);
    const entity = ref(null);
    const pagination = ref(null);
    const error = ref(null);

    const transformDataStoreToRequest = (data) => {
        const fileGroups = data.fileGroups;
        let items = [];

        fileGroups.forEach((fileGroup) => {
            fileGroup.data.forEach((fileBlock) => {
                items.push({ ...fileBlock.value });
            });
        });

        return Object.values(items);
    }

    const getEntities = async (params = null, url = '/developers/docs') => {
        let config = {
            params: params,
        }

        await axios.get(url, config)
            .then((r) => {
                entities.value = r.data.data.entities;
                pagination.value = r.data.data.pagination;
            }).catch((e) => {
                console.log(e);
            })
    }

    const getEntityById = async (id) => {
        await axios.get('/developers/docs/' + id)
            .then((r) => {
                entity.value = r.data.data;
            }).catch((e) => {
                console.log(e);
            })
    }

    const storeEntity = async (data) => {
        data.files = transformDataStoreToRequest(data);
        data.fileGroups = [];

        await axios.post('/developers/docs/store', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const deleteEntities = async (data) => {

        let config = {
            params: {
                ids: data
            },
        }
        await axios.delete('/developers/docs', config)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const setEmptyEntity = async () => {
        entity.value = {
            name: '',
            type: '',
            doc_type: '',
            status: '',
            developer_id: '',
            description: '',
            remarks: '',
            deadline: '',
            newFiles: [],
            files: [],
            fileGroups: [],
            developers: [],
            doc_types_info: [],
            doc_types: [],
            doc_types_agreed: [],
            messages: [],
            draft_developer_type_id: [],
            draft_developers: [],
        }
    };

    const resetEntity = async () => {
        entity.value = null;
    }

    const restoreEntities = async (data) => {
        let params = {
            ids: data
        }

        await axios.post('/developers/docs/restore', params)
            .then((r) => {
                let response = r.data;

                if (response.success) {
                    entity.value = r.data.data;
                    getEntities();
                } else {
                    error.value = response.error.message
                }
            }).catch((e) => {
                if (e.response.status === 422) {
                    let errorMessage = [];
                    for (let key in e.response.data.errors) {
                        errorMessage.push(e.response.data.errors[key][0]);
                    }
                    error.value = errorMessage.join(' ');
                } else {
                    error.value = 'Ошибка сервера. Попробуйте позже';
                }
            })
    }

    const getDirectories = async (params = null) => {
        let config = {
            params: params,
        }

        await axios.get('/developers/docs/directories', config)
            .then((r) => {
                developers.value = r.data.data.developers;
                statusList.value = r.data.data.statusList;
                statusListAll.value = r.data.data.statusListAll;
                typeList.value = r.data.data.typeList;
                developerTypes.value = r.data.data.developerTypes;
                typeListAllowed.value = r.data.data.typeListAllowed;
                documentTypeList.value = r.data.data.documentTypeList;
                documentTypeListAll.value = r.data.data.documentTypeListAll;
                changeStatusList.value = r.data.data.changeStatusList;
                createStatusList.value = r.data.data.createStatusList;
            }).catch((e) => {
                console.log(e);
            })
    }

    const deleteFile = async (fileId) => {
        await axios.post('/developers/docs/delete-file/' + fileId)
            .then((r) => {
                entity.value.files = r.data.data.files
            }).catch((e) => {
                console.log(e);
            })
    }

    return {
        pagination,
        developers,
        statusList,
        statusListAll,
        documentTypeList,
        documentTypeListAll,
        changeStatusList,
        createStatusList,
        typeList,
        typeListAllowed,
        developerTypes,
        entities,
        entity,
        error,
        setEmptyEntity,
        getEntities,
        getEntityById,
        storeEntity,
        deleteEntities,
        resetEntity,
        restoreEntities,
        getDirectories,
        deleteFile,
    }
})